
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Signature extends Vue {
    signature = {
        name: 'Demo Gebruiker',
        role: 'Developer',
        email: 'demo@decheckers.be',
        phone: '+32 (0)485 466 960',
        twitterHandle: 'deCheckers_be',
        linkedin: 'https://www.linkedin.com/company/decheckers/',
    }

    copySignature() {
        const signature = document.getElementById("decheckers-signature");
        const range = document.createRange();
        range.selectNodeContents(signature as HTMLElement);
        const selection = window.getSelection();
        selection!.removeAllRanges();
        selection!.addRange(range);
        document.execCommand("copy");
        selection!.removeAllRanges();
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            text: "Handtekening gekopieerd",
            color: "success",
        })
    }
}
